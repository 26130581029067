:root {
  --black: rgb(28, 28, 28);
  --purple: rgb(100, 61, 136);
  --glow-color: rgb(217, 176, 255);
  --glow-spread-color: rgba(191, 123, 255, 0.781);
  --enhanced-glow-color: rgb(231, 206, 255);
  --grey: rgb(121, 111, 111);
  --light: rgb(222, 207, 207);
}

::-moz-selection {
  background: var(--purple);
  color: var(--glow-color);
}

::selection {
  background: var(--purple);
  color: var(--glow-color);
}

body {
  margin: 0;
  padding: 0;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  background-color: rgb(0,0,0,.4);
  border-radius: .4em;
  padding: .4em;
  padding-bottom: .1em;
  color: var(--light);
  margin-left: .1em;
  font-size: 1.2em;
  user-select: all;
}

h1 {
  font-size: 2em;
  color: var(--glow-color);
  text-shadow: 2px 2px 4px black;
  text-align: center;
}

@media only screen and (max-width: 1399px) {

  body {
    background: var(--black);
  }

  .full-width {
    width: 100vw!important;
  }
  
  #Navbar {
    position: sticky;
    width: 98%;
    padding: 1%;
    left: 0%;
    top: 0%;
    height: 5%;
    background-color: var(--black);
  }

  .Page {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    backdrop-filter: blur(2em);
  }

  #all_titles {
    opacity: 0;
    pointer-events: none;
  }

  .titles {
    padding: 5%;
  }

  #hamburger {
    display: block;
    z-index: 2;
    height: 90%;
    width: auto;
    filter: invert(1);
    margin-left: auto;
    margin-right: auto;
    transition: all.4s;
  }

  #hamburger:hover {
    opacity: .4;
    cursor: pointer;
    outline: none;
  }
}

@media only screen and (min-width: 1400px) {

  body {
    background: url("./background.jpg");
  }

  .full-width {
    width: 60vw!important;
  }

  .infos-pretty {
    width: 80%;
  }

  .infos-special {
    width: 100%;
    height: 100%;
    border-left: 3px solid var(--enhanced-glow-color);
    padding-left: 1%;
  }

  #Navbar {
    position: sticky;
    display: flex;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: fit-content!important;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-left: auto;
    margin-right: auto;
    background: url("https://sunil.fr/images/background.jpg");
    background-color: rgb(0,0,0,.4);
    background-blend-mode: multiply;
    background-attachment: fixed;
    border-bottom: .1em solid var(--grey);
    backdrop-filter: blur(2em);
    border-radius: 1.5em 1.5em 0 0;
  }

  #Navbar:hover {
    border-bottom: .1em solid var(--light);
  }

  .Page {
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    border-radius: 1.5em;
    backdrop-filter: blur(1em);
  }

  #all_titles {
    display: inline-flex;
  }

  #hamburger {
    display: none;
  }
}

#all_titles {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.Page {
  position: fixed;
  background-color: rgb(0,0,0,.4);
  overflow-y: scroll;
  overflow-x: hidden;
  /* HIDE SCROLLBAR FOR FIREFOX IE EDGE */
  scrollbar-width: none;
}

/* HIDE SCROLLBAR FOR CHROME OPERA SAFARI */
.Page::-webkit-scrollbar {
  display: none;
}

.flex {
  display: flex;
}

#Navbar {
  text-align: center;
  transition: all.4s;
}

a {
  text-decoration: none;
  color: var(--light);
  transition: all.4s;
}

p {
  font-size: 1em;
  color: var(--light);
  text-align: center;
  transition: all.4s;
}

.titles {
  font-size: 1.2em;
  margin-left: auto;
  margin-right: auto;
}

.titles a {
  color: var(--grey);
}

.titles:hover {
  animation: shake .12s infinite;
}

.titles:hover a {
  color: var(--light);
}

.ctn {
  height: 10vh;
  margin: 1%;
  margin-top: 5%;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.ctn p {
  text-align: left;
  font-size: 1.2em;
}

@keyframes shake {
  20% {
    transform: rotate(4deg) translate(1px);
  }

  60% {
    transform: rotate(-4deg) translate(-2px);
  }

  100% {
    transform: rotate(0deg) translate(1px);
  }
}

a {
  margin-left: auto;
  margin-right: auto;
}

.grid {
  display: grid;
}

.grid a {
  padding: 9px;
}

.button {
  margin: 5%;
  border: .17em solid var(--glow-color);
  padding: 1em 3em;
  width: 400px;
  margin-left: calc(50% - 200px);
  color: var(--glow-color);
  font-size: 1.1em;
  font-weight: bold;
  background-color: transparent;
  border-radius: 1em;
  outline: none;
  box-shadow: 0 0 .7em .14em var(--glow-color),
  0 0 4em 1em var(--glow-spread-color),
  inset 0 0 .55em .14em var(--glow-color);
  text-shadow: 0 0 .33em var(--glow-color);
  position: relative;
  transition: all.3s;
}

.button:hover {
  cursor: pointer;
  color: var(--purple);
  background-color: var(--glow-color);
}

.button:active, .button:focus {
  transform: scale(.9);
  color: var(--purple);
  background-color: var(--glow-color);
  box-shadow: 0 0 .4em .09em var(--glow-color),
  0 0 1em .4em var(--glow-spread-color),
  inset 0 0 .33em .09em var(--glow-color);
}

#popup {
  display: none;
  overflow: hidden;
  opacity: 0;
  z-index: 99;
  background-color: rgb(0,0,0,.4);
  backdrop-filter: blur(.4em);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.popup-msg {
  text-align: center;
  padding-top: 30vh;
  width: 100%;
  vertical-align: middle;
  height: 100%;
  color: rgb(144,25,25);
  text-shadow: 2px 2px 4px black;
  font-weight: bold;
  font-size: 2.5em;
}

.infos,
.infos-center {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.infos {
  text-align: left;
}

.infos-center {
  text-align: center!important;
}

.number {
  background-color: var(--enhanced-glow-color);
  color: var(--black);
  padding: 4px;
  border-radius: 7px;
  user-select: all;
}

.info-btn {
  float: right;
  color: var(--enhanced-glow-color);
  font-weight: bold;
  background-color: transparent;
  padding: 4px;
  border-radius: 50px;
  border: 2px solid var(--enhanced-glow-color);
  width: 2em;
  height: 2em;
}

.info-btn:hover {
  cursor: pointer;
  opacity: .4;
}

.infos-pretty {
  background-color: rgb(0,0,0,.4);
  border-radius: 25px;
  padding: 25px;
}

.infos-special {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  line-height: 200%;
}

table {
  border-collapse:collapse;
}

td {
  line-height: 2em;
  border-top: 1px solid var(--grey);
}

.noborder {
  border: none!important;
}

td code {
  float: right;
  user-select: all;
}

.full-width {
  padding: 2%;
  background: linear-gradient(to right, rgb(0,0,0,.9), rgb(0,0,0,.7), rgb(0,0,0,.7), rgb(0,0,0,.9));
}

.link {
  color: var(--purple);
}

.link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.image {
  float: right;
  height: 25vh;
}

.nft {
  height: 10%;
  width: 10%;
  margin: 1%;
  opacity: .7;
}

.nft:hover {
  cursor: pointer;
  transform: scale(1.04);
  opacity: 1;
}

.ldd {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgb(0,0,0,.4);
  text-align: center;
}

#listee {
  margin-bottom: 50px;
}

.btn-left, .btn-right {
  width: 25vw;
  padding: 0;
  padding-top: 1%;
  padding-bottom: 1%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: var(--glow-color);
  transition: all.4s;
}

.btn-left:hover, .btn-right:hover {
  cursor: pointer;
  transform: scale(1.25);
}

.btn-left {
  float: left;
}

.btn-right {
  float: right;
}

.contentt-flex {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(0,0,0,.4);
  border-radius: 25px;
  margin-top: 40vh;
  width: 50vw;
}

.al-left {
  text-align: left;
}

.pretty2 {
  border-radius: 0px!important;
  width: 100%!important;
}

.btn-center {
  margin-left: auto!important;
  margin-right: auto!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.input {
  background-color: rgb(0,0,0,.4);
  color: white;
  text-align: center;
  width: fit-content;
  height: auto;
  border: none;
  outline: none;
  padding: 9px;
  margin: 125px;
  border-radius: 1em;
  border: .17em solid var(--glow-color);
  color: var(--glow-color);
  font-size: 1.1em;
  font-weight: bold;
  box-shadow: 0 0 .7em .14em var(--glow-color),
  0 0 4em 1em var(--glow-spread-color),
  inset 0 0 .55em .14em var(--glow-color);
  text-shadow: 0 0 .33em var(--glow-color);
  transition: all.4s;
}
input:hover {
  box-shadow: 0 0 .4em .09em var(--glow-color),
  0 0 1em .4em var(--glow-spread-color),
  inset 0 0 .33em .09em var(--glow-color);
}